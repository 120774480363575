import config from "./key";

import axios from "axios";

export const getBannerDatas = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: config.key.API_URL + "/banner/getBannerDetails",
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const get_Blog_details = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: config.key.API_URL + "/blog/get_blog_details",
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const get_Book_List = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: config.key.API_URL + "/contact/getBookList",
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const postData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/contact/sendMessage",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const sendMessageQuate = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/contact/sendMessage",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const downloadersDetails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/contact/downloaderDetails",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
export const subscribEmailAPI = async (data) => {
  console.log(data, "==-=data=-=-=");
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/banner/subscriber",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const sendCareer = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/contact/carrearAPI",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
export const internuserFormSubmit = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/internFormSubmit",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
export const jobuserFormSubmit = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/jobFormSubmit",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const get_intern_details_one = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/get_intern_details_one",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
export const get_job_details_one = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/get_job_details_one",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
export const sendMessageQuateDetails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/contact/sendMessageQuoteDet",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const algotradepageform = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/contact/algotradepageform",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
export const get_Blog = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/get_Blog",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
export const get_Catagory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/get_Catagory",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const get_intern_datas = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: config.key.API_URL + "/blog/get_intern_data",
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};

export const get_job_datas = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: config.key.API_URL + "/blog/get_job_data",
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
export const choose_Catagory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/choose_Catagory",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    console.log(error);
    var data = { status: "false" };
    return data;
  }
};
export const blog_contact_details = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/blog_contact_details",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    console.log(error);
    var data = { status: "false" };
    return data;
  }
};
export const get_catagory_Blog = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/get_catagory_Blog",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    console.log(error);
    var data = { status: "false" };
    return data;
  }
};
export const choose_Blog_page_Details = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/choose_Blog_page_Details",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    console.log(error);
    var data = { status: "false" };
    return data;
  }
};
export const search_list = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/search_list",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    console.log(error);
    var data = { status: "false" };
    return data;
  }
};

export const related_blog = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/blog/related_blog",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    console.log(error);
    var data = { status: "false" };
    return data;
  }
};

//New form

export const sendServiceContacts = async (data) => {
  try {
    console.log("------");
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/contact/sendServiceContact",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
export const sendcontactpageContacts = async (data) => {
  try {
    console.log("------");
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/contact/sendcontactpageContacts",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    console.log("======", error);
    var data = { status: "false" };
    return data;
  }
};

export const sendfooterServiceContact = async (data) => {
  try {
    console.log("------");
    let respData = await axios({
      method: "post",
      url: config.key.API_URL + "/contact/sendfooterServiceContact",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return respData.data;
  } catch (error) {
    var data = { status: "false" };
    return data;
  }
};
