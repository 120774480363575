const httpService = {
  sendMessageApi: "/contact/sendMessage",
  sendMessageApiQuote: "/contact/sendMessageQuote",
  carrearAPI: "/contact/carrearAPI",
  sendMessageQuate: "/contact/sendMessageQuoteDet",
  sendServiceContactDetails: "/contact/sendServiceContact",
  subscriber: "/banner/subscriber",
  choose_Catagory: "/blog/choose_Catagory",
  get_Blog: "/blog/get_Blog",
  get_Blog_details: "/blog/get_Blog_details",
  get_Catagory: "/blog/get_Catagory",
  get_catagory_Blog: "/blog/get_catagory_Blog",
  choose_Blog_page_Details: "/blog/choose_Blog_page_Details",
  search_list: "/blog/search_list",
  blog_contact_details: "/blog/blog_contact_details",
  related_blog: "/blog/related_blog",
  // getBookList:"/contact/getBookList"
  downloader_Details: "/contact/downloader_Details",
  algotradepageform: "/contact/algotradepageform",

  //BELEAF CAREER INTERSHIP API

  get_intern_data: "/blog/get_intern_data",
  get_intern_details_one: "/blog/get_intern_details_one",
  internFormSubmit: "/blog/internFormSubmit",

  //BELEAF CAREER JOB API

  get_job_datas: "/blog/get_job_datas",
  get_job_details_one: "/blog/get_job_details_one",
  jobuserFormSubmit: "/blog/jobFormSubmit",
};

export default httpService;
