import { useState } from "react";
import { Drawer } from "@material-ui/core";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuClose = () => setMobileMenuOpen(false);

  const handleMobileClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <header className="headerWrapper">
        <nav className="headerWrapper__navigation">
          {/* logo and links container */}
          <div className="header-flex">
            <div className="headerWrapper__logo--container">
              <a href="#">
                <img src={require("../assets/logo.webp")} alt="Logo" />
              </a>
            </div>
          </div>

          {/* headerlinks */}
          <ul className="headerLinks">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#types">Types</a>
            </li>
            <li>
              <a href="#features">Features</a>
            </li>
            <li>
              <a href="#importance">Importance</a>
            </li>
            {/* <li>
              <a href="#process">Process</a>
            </li> */}
            <li>
              <a href="#benefits">Benefits</a>
            </li>
            <li>
              <a href="#conclusion">Conclusion</a>
            </li>
          </ul>

          {/* btn */}
          <div className="btn-none">
            <div className="d-flex gap-3 align-items-center">
              <a
                className="secondary-btn"
                // href="https://beleaftechnologies.com/contact-us"
                // target="_blank"
                href="#contact"
              >
                Get Demo
              </a>
            </div>
          </div>

          <div className="headerWrapper__navigation--hamburger">
            <div className="landing-ham" onClick={handleMobileClick}>
              {!mobileMenuOpen ? (
                <i class="fa-solid fa-bars"></i>
              ) : (
                <i class="fa-solid fa-xmark"></i>
              )}
            </div>
          </div>

          {/* mobile sidebar */}
          <Drawer
            anchor="left"
            open={mobileMenuOpen}
            onClose={handleMobileMenuClose}
            className="primary__landing-drawer-menu"
          >
            <div className="mobileMenu">
              {/* Logo Section */}
              <div className="logo_new_sectio mobileMenu-logo">
                <a href="#" onClick={() => handleMobileMenuClose(false)}>
                  <img src={require("../assets/logo.webp")} alt="Logo" />
                </a>
              </div>

              <div className="primary__drawer-btn mt-4">
                <li onClick={() => handleMobileMenuClose(false)}>
                  <a href="#" className="primary-landing-btn">
                    Home
                  </a>
                </li>
                <li onClick={() => handleMobileMenuClose(false)}>
                  <a href="#about" className="primary-landing-btn">
                    About
                  </a>
                </li>
                <li onClick={() => handleMobileMenuClose(false)}>
                  <a href="#types" className="primary-landing-btn">
                    Types
                  </a>
                </li>
                <li onClick={() => handleMobileMenuClose(false)}>
                  <a href="#features" className="primary-landing-btn">
                    Features{" "}
                  </a>
                </li>
                <li onClick={() => handleMobileMenuClose(false)}>
                  <a href="#importance" className="primary-landing-btn">
                    Importance
                  </a>
                </li>
                {/* <li onClick={() => handleMobileMenuClose(false)}>
                  <a href="#process" className="primary-landing-btn">
                    Process
                  </a>
                </li> */}
                <li onClick={() => handleMobileMenuClose(false)}>
                  <a href="#benefits" className="primary-landing-btn">
                    Benefits
                  </a>
                </li>
                <li onClick={() => handleMobileMenuClose(false)}>
                  <a href="#conclusion" className="primary-landing-btn">
                    Conclusion
                  </a>
                </li>

                <li
                  className="mobile-btn"
                  onClick={() => handleMobileMenuClose(false)}
                >
                  <div>
                    <a
                      // href="https://beleaftechnologies.com/contact-us"
                      className="secondary-btn"
                      href="#contact"
                    >
                      Get Demo
                    </a>
                  </div>
                </li>
              </div>
            </div>
          </Drawer>
        </nav>
      </header>
    </>
  );
};

export default Header;
