import useState from "react-usestateref";
import httpService from "../config/httpCall";
import { sendcontactpageContacts } from "../config/apiCall";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PopupContact = () => {
  const [selectedPlatform2, setSelectedPlatform2] = useState("whatsApp");

  const NewFormValue = {
    newformmediumValue: "",
    newformmedium: "whatsApp",
    newformmessage: "",
    newformmobile: "",
    newformuserName: "",
    newformmobilecode: "",
  };

  const [newValue, setnewValue, newValueref] = useState(NewFormValue);

  const { newformuserName, newformmessage } = newValue;

  const [
    newformmediumValueErr,
    setnewformmediumValueErr,
    newformmediumValueErrref,
  ] = useState(false);

  const [newformmediumErr, setnewformmediumErr] = useState(false);
  const [newformmessageErr, setnewformmessageErr, newformmessageErrref] =
    useState(false);
  const [newformmobileErr, setnewformmobileErr, newformmobileErrref] =
    useState(false);
  const [newformuserNameErr, setnewformuserNameErr, newformuserNameErrref] =
    useState(false);
  const [newformmobilecodeErr, setnewformmobilecodeErr] = useState(false);
  const [validationErr, setvalidationErr, validationErrref] = useState("");
  const [platformInput, setPlatformInput, platformInputref] = useState("");

  const [NewFormNMobileCode, setNewFormNMobileCode] = useState();
  const [NewFormMobileValue, setNewFormMobileValue] = useState();
  const [NewFormcountry, setNewFormcountry] = useState();

  const handleNewFormMobile = (value, countryObj, e, formattedValue) => {
    setNewFormMobileValue(value);
    setNewFormNMobileCode(countryObj.dialCode);
    setNewFormcountry(countryObj.name);
    newValueref.current["newformmobilecode"] = countryObj.dialCode;
    newValueref.current["newformmobile"] = value;
    newValueref.current["NewFormcountry"] = countryObj.name;
    setSelectedPlatform2("whatsApp");
    setPlatformInput("");
    validate(newValueref.current);
    console.log(newValueref.current);
  };

  const validate = async (formData) => {
    try {
      var error = {};
      console.log(formData.newformmobilecode);
      console.log(formData.newformmobilecode.length);

      const countryCodeLength = formData.newformmobilecode.length;

      const actualMobileLength =
        formData.newformmobile.length - countryCodeLength;

      if (formData.newformuserName.trim() === "") {
        setnewformuserNameErr(true);
        error.newformuserName = "Must enter the field";
        setvalidationErr(error);
        return;
      } else if (formData.newformuserName.length < 3) {
        setnewformuserNameErr(true);

        error.newformuserName = "Invalid username, Must be 3-50 characters";
        setvalidationErr(error);
        return;
      } else if (formData.newformmobile.trim() === "") {
        setnewformuserNameErr(false);

        setnewformmobileErr(true);
        error.newformmobile = "Must enter the field";
        setvalidationErr(error);
        return;
      } else if (actualMobileLength < 7) {
        setnewformuserNameErr(false);
        setnewformmobileErr(true);
        error.newformmobile =
          "Invalid number, Must be at least 7 digits excluding country code";
        setvalidationErr(error);
        return;
      } else if (
        (selectedPlatform2 === "email" && platformInputref.current === "") ||
        platformInputref.current.length == 0
      ) {
        setnewformmobileErr(false);

        setnewformmediumValueErr(true);
        error.newformmediumValue = "Must enter the field";
        setvalidationErr(error);
        return;
      } else if (
        selectedPlatform2 === "email" &&
        !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
          platformInputref.current
        )
      ) {
        setnewformmediumValueErr(true);
        error.newformmediumValue = "Invalid email format";

        setvalidationErr(error);
        return;
      } else if (
        ["whatsApp", "skype", "telegram"].includes(selectedPlatform2)
      ) {
        if (platformInputref.current.trim() === "") {
          setnewformmediumValueErr(true);
          error.newformmediumValue = "Must enter the field";
          setvalidationErr(error);
          return;
        } else if (
          selectedPlatform2 === "whatsApp" &&
          platformInputref.current.length < 7
        ) {
          setnewformmediumValueErr(true);
          error.newformmediumValue = `Whatsapp number must be at least 7 digits`;
          setvalidationErr(error);
          return;
        } else if (
          selectedPlatform2 === "skype" &&
          platformInputref.current.length < 5
        ) {
          setnewformmediumValueErr(true);
          error.newformmediumValue = ` Skype ID / Number must be at least 5 characters`;
          setvalidationErr(error);
          return;
        } else if (platformInputref.current.length < 5) {
          setnewformmediumValueErr(true);
          error.newformmediumValue = ` Telegram ID / Number must be at least 5 characters`;
          setvalidationErr(error);
          return;
        } else {
          setnewformuserNameErr(false);

          setnewformmediumValueErr(false);
        }
      }

      if (formData.newformmessage.trim() === "") {
        setnewformmediumValueErr(false);
        setnewformmessageErr(true);
        error.newformmessage = "Must enter the field";
        setvalidationErr(error);
      } else if (formData.newformmessage.length < 3) {
        setnewformmessageErr(true);
        error.newformmessage = "Message must contain at least 3 characters";
        setvalidationErr(error);
      } else if (formData.newformmessage.length > 250) {
        setnewformmessageErr(true);
        error.newformmessage = "Message can't exceed 250 characters";
        setvalidationErr(error);
      } else {
        setnewformmessageErr(false);
      }

      setvalidationErr(error);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePlatformInputChange = (e) => {
    const value = e.target.value;
    setPlatformInput(value);
    if (value && value.length > 0) {
      setnewformmediumErr(false);
      setvalidationErr((prev) => ({ ...prev, newformmedium: "" }));
    } else {
      setnewformmediumErr(true);
      setvalidationErr((prev) => ({
        ...prev,
        newformmedium: "Must enter the field",
      }));
    }
    // newValueref.current.newformmessage = "";
    validate(newValueref.current);
    if (
      selectedPlatform2 === "email" &&
      !/^[^\s@]+@[^\s@]+\.(com)$/.test(platformInputref.current)
    ) {
      validate(newValueref.current);
    }
  };

  const handleKeyPress = (e, maxLength, type) => {
    if (
      e.target.value.length >= maxLength &&
      ![
        "Backspace",
        "Delete",
        "Tab",
        "Escape",
        "Enter",
        "ArrowLeft",
        "ArrowRight",
      ].includes(e.key)
    ) {
      e.preventDefault();
      return;
    }

    if (type === "email") {
      const validEmailChars = /^[a-zA-Z0-9@._-]+$/;
      if (
        !validEmailChars.test(e.key) &&
        ![
          "Backspace",
          "Delete",
          "Tab",
          "Escape",
          "Enter",
          "ArrowLeft",
          "ArrowRight",
        ].includes(e.key)
      ) {
        e.preventDefault();
      }
      return;
    }

    if (type === "number") {
      const cursorPosition = e.target.selectionStart;
      const value = e.target.value;

      // Prevent space at the start
      if (e.key === " " && cursorPosition === 0) {
        e.preventDefault();
        return;
      }

      // Allow only digits, plus symbol at the start, spaces in the middle, and control keys
      if (
        !/[0-9 ]/.test(e.key) &&
        !(e.key === "+" && cursorPosition === 0) &&
        ![
          "Backspace",
          "Delete",
          "Tab",
          "Escape",
          "Enter",
          "ArrowLeft",
          "ArrowRight",
        ].includes(e.key)
      ) {
        e.preventDefault();
      }
      return;
    }

    if (type === "text") {
      const cursorPosition = e.target.selectionStart;
      const value = e.target.value;

      if (e.key === " " && cursorPosition === 0) {
        e.preventDefault();
        return;
      }
      if (
        value.length < maxLength ||
        [
          "Backspace",
          "Delete",
          "Tab",
          "Escape",
          "Enter",
          "ArrowLeft",
          "ArrowRight",
        ].includes(e.key)
      ) {
        return;
      }

      e.preventDefault();
    }
  };

  const handlePlatformSelect = (e, platform) => {
    e.preventDefault();
    setSelectedPlatform2(platform);
    setPlatformInput("");
  };

  const handleNewFormChange = async (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formData = { ...newValue, ...{ [name]: value } };
      setnewValue(formData);
      await validate(formData);

      if (selectedPlatform2 && !platformInputref.current) {
        setnewformmediumValueErr(true);
      } else {
        setnewformmediumValueErr(false);
      }

      // if (name === "newformmessage") {
      //   if (!value.trim()) {
      //     setnewformmessageErr(true);
      //   } else {
      //     setnewformmessageErr(false);
      //   }
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const [buttonLoader, setbuttonLoader] = useState(false);

  const sendServiceContact = async (e) => {
    try {
      e.preventDefault();
      validate(newValueref.current);
      console.log(newValueref.current);
      console.log(
        newValueref.current.newformuserName,
        newformuserNameErrref.current,
        newformmessageErrref.current,
        newformmobileErrref.current,
        newformmediumValueErrref.current,
        newformmobilecodeErr,
        newValueref.current.newformmessage
      );

      if (
        newValueref.current.newformuserName !== "" &&
        newformuserNameErrref.current === false &&
        newformmessageErrref.current === false &&
        newformmobileErrref.current === false &&
        newformmediumValueErrref.current == false &&
        newformmobilecodeErr === false &&
        newValueref.current.newformmessage != ""
      ) {
        // setbuttonLoader(true);

        newValueref.current.newformmedium = selectedPlatform2;
        newValueref.current.newformmediumValue = platformInputref.current;

        var obj = {
          apiUrl: httpService.sendServiceContactDetails,
          payload: newValueref.current,
        };
        var messageSent = await sendcontactpageContacts(obj);
        setbuttonLoader(false);

        if (messageSent.status == true) {
          toast.success(messageSent.Message);
        } else {
          toast.error("error", messageSent.Message);
        }
        newValueref.current.newformuserName = "";
        newValueref.current.newformmessage = "";
        newValueref.current.newformmobile = "";
        newValueref.current.newformmobilecode = "";
        setNewFormMobileValue("");
        setPlatformInput("");
        setNewFormNMobileCode("");
        setNewFormcountry();
        handleNewFormMobile("", "", "", "");
      } else {
        validate(newValueref.current);
      }
    } catch (errr) {
      console.log("errr", errr);
    }
  };

  const cancelpopup = () => {};

  return (
    <div>
      {/* New Pop Up */}
      <div id="myModal" class="modal fade contact_model" role="dialog">
        <div class="modal-dialog modal-md ">
          <div class="modal-content ">
            <div className=" contact-popup-form">
              <form>
                <h4 className="landing__contact--form-title">Your Details</h4>

                <div className="mt-4">
                  <label htmlFor="form-name">Name</label> <br />
                  <input
                    type="text"
                    id="form-name"
                    placeholder="Enter your name"
                    className="landing__form-input margin-bottom-land"
                    name="newformuserName"
                    autoComplete="off"
                    value={newformuserName}
                    // name="newformuserName"
                    max="50"
                    maxLength="50"
                    onChange={handleNewFormChange}
                    onKeyPress={(e) => handleKeyPress(e, 50, "text")}
                  />
                  {newformuserNameErrref.current &&
                  newformuserNameErrref.current === true ? (
                    <div className="err-text mt-3">
                      {" "}
                      {validationErrref.current.newformuserName}{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-4">
                  <label htmlFor="mobile">Mobile Number</label> <br />
                  <PhoneInput
                    country={"us"}
                    value={NewFormMobileValue}
                    inputProps={{
                      name: "phonenumber",
                      required: true,
                    }}
                    autoComplete="off"
                    className="form-phone landing__form-input margin-bottom-land"
                    onChange={(value, countryObj, e, formattedValue) =>
                      handleNewFormMobile(value, countryObj, e, formattedValue)
                    }
                  />
                  {newformmobileErrref.current &&
                  newformmobileErrref.current === true ? (
                    <div className="err-text mt-3">
                      {validationErrref.current.newformmobile}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-4">
                  <label htmlFor="form-contact">Contact Medium</label> <br />
                  <div className="form-contact-medium">
                    <button
                      type="button"
                      className="dropdown-toggle landing__form-dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {selectedPlatform2 == "whatsApp" ? (
                        <i className="fa-brands fa-whatsapp"></i>
                      ) : selectedPlatform2 == "telegram" ? (
                        <i className="fa-brands fa-telegram"></i>
                      ) : selectedPlatform2 == "skype" ? (
                        <i className="fa-brands fa-skype"></i>
                      ) : selectedPlatform2 == "email" ? (
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      ) : (
                        ""
                      )}
                    </button>
                    <div className="dropdown-menu landing__form-drop-menu">
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => handlePlatformSelect(e, "whatsApp")}
                      >
                        <i className="fa-brands fa-whatsapp"></i>
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => handlePlatformSelect(e, "telegram")}
                      >
                        <i className="fa-brands fa-telegram"></i>
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => handlePlatformSelect(e, "skype")}
                      >
                        <i className="fa-brands fa-skype"></i>
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => handlePlatformSelect(e, "email")}
                      >
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </a>
                    </div>

                    {selectedPlatform2 && (
                      <input
                        type="text"
                        className="landing__contact-medium-input"
                        placeholder={`Enter your ${selectedPlatform2} ${
                          selectedPlatform2 == "email"
                            ? "Address"
                            : selectedPlatform2 === "whatsApp"
                            ? "number"
                            : "number / ID"
                        }`}
                        maxLength={
                          selectedPlatform2 === "email"
                            ? 250
                            : selectedPlatform2 === "whatsApp"
                            ? 15
                            : 32
                        }
                        value={platformInputref.current}
                        autoComplete="off"
                        onChange={handlePlatformInputChange}
                        onKeyPress={(e) =>
                          handleKeyPress(
                            e,
                            selectedPlatform2 === "email"
                              ? 250
                              : selectedPlatform2 === "whatsApp"
                              ? 15
                              : 32,
                            selectedPlatform2 === "email"
                              ? "email"
                              : selectedPlatform2 === "whatsApp"
                              ? "number"
                              : "text"
                          )
                        }
                      />
                    )}
                  </div>
                  {newformmediumValueErrref.current &&
                  newformmediumValueErrref.current === true ? (
                    <span className="err-text mt-3">
                      {validationErrref.current.newformmediumValue}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-4">
                  <label htmlFor="message">Tell Us About Your Project</label>
                  <br />
                  <textarea
                    id="message"
                    placeholder="Message"
                    className="landing__form-input landing-textarea landing__form-input margin-bottom-land"
                    rows="5"
                    name="newformmessage"
                    value={newformmessage}
                    max="250"
                    maxLength="250"
                    autoComplete="off"
                    onChange={handleNewFormChange}
                    onKeyPress={(e) => handleKeyPress(e, 250, "text")}
                  ></textarea>

                  {newformmessageErrref.current == true ? (
                    <div className="err-text mt-3">
                      {validationErrref.current.newformmessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="text-center mt-4">
                  {buttonLoader == true ? (
                    <button className="primary-btn" disabled>
                      loading....
                      <span className="mx-2 right-arr">
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </button>
                  ) : (
                    <button
                      className="primary-btn"
                      onClick={sendServiceContact}
                    >
                      Submit
                      <span className="mx-2 right-arr">
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupContact;
