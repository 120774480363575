import useState from "react-usestateref";
import Header from "./Header";
import httpService from "../config/httpCall";
import { sendcontactpageContacts } from "../config/apiCall";
import { toastAlert } from "../config/toastAlert";
import PhoneInput from "react-phone-input-2";
import TEMPLE from "../assets/temple.webp";
import GATE from "../assets/gate.webp";
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopupContact from "./PopupContact";

const Landing = () => {
  const [selectedPlatform2, setSelectedPlatform2] = useState("whatsApp");

  const NewFormValue = {
    newformmediumValue: "",
    newformmedium: "whatsApp",
    newformmessage: "",
    newformmobile: "",
    newformuserName: "",
    newformmobilecode: "",
  };

  const [newValue, setnewValue, newValueref] = useState(NewFormValue);

  const { newformuserName, newformmessage } = newValue;

  const [
    newformmediumValueErr,
    setnewformmediumValueErr,
    newformmediumValueErrref,
  ] = useState(false);

  const [newformmediumErr, setnewformmediumErr] = useState(false);
  const [newformmessageErr, setnewformmessageErr, newformmessageErrref] =
    useState(false);
  const [newformmobileErr, setnewformmobileErr, newformmobileErrref] =
    useState(false);
  const [newformuserNameErr, setnewformuserNameErr, newformuserNameErrref] =
    useState(false);
  const [newformmobilecodeErr, setnewformmobilecodeErr] = useState(false);
  const [validationErr, setvalidationErr, validationErrref] = useState("");
  const [platformInput, setPlatformInput, platformInputref] = useState("");

  const [NewFormNMobileCode, setNewFormNMobileCode] = useState();
  const [NewFormMobileValue, setNewFormMobileValue] = useState();
  const [NewFormcountry, setNewFormcountry] = useState();

  const handleNewFormMobile = (value, countryObj, e, formattedValue) => {
    setNewFormMobileValue(value);
    setNewFormNMobileCode(countryObj.dialCode);
    setNewFormcountry(countryObj.name);
    newValueref.current["newformmobilecode"] = countryObj.dialCode;
    newValueref.current["newformmobile"] = value;
    newValueref.current["NewFormcountry"] = countryObj.name;
    setSelectedPlatform2("whatsApp");
    setPlatformInput("");
    validate(newValueref.current);
    console.log(newValueref.current);
  };

  const validate = async (formData) => {
    try {
      var error = {};
      console.log(formData.newformmobilecode);
      console.log(formData.newformmobilecode.length);

      const countryCodeLength = formData.newformmobilecode.length;

      const actualMobileLength =
        formData.newformmobile.length - countryCodeLength;

      if (formData.newformuserName.trim() === "") {
        setnewformuserNameErr(true);
        error.newformuserName = "Must enter the field";
        setvalidationErr(error);
        return;
      } else if (formData.newformuserName.length < 3) {
        setnewformuserNameErr(true);

        error.newformuserName = "Invalid username, Must be 3-50 characters";
        setvalidationErr(error);
        return;
      } else if (formData.newformmobile.trim() === "") {
        setnewformuserNameErr(false);

        setnewformmobileErr(true);
        error.newformmobile = "Must enter the field";
        setvalidationErr(error);
        return;
      } else if (actualMobileLength < 7) {
        setnewformuserNameErr(false);
        setnewformmobileErr(true);
        error.newformmobile =
          "Invalid number, Must be at least 7 digits excluding country code";
        setvalidationErr(error);
        return;
      } else if (
        (selectedPlatform2 === "email" && platformInputref.current === "") ||
        platformInputref.current.length == 0
      ) {
        setnewformmobileErr(false);

        setnewformmediumValueErr(true);
        error.newformmediumValue = "Must enter the field";
        setvalidationErr(error);
        return;
      } else if (
        selectedPlatform2 === "email" &&
        !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
          platformInputref.current
        )
      ) {
        setnewformmediumValueErr(true);
        error.newformmediumValue = "Invalid email format";

        setvalidationErr(error);
        return;
      } else if (
        ["whatsApp", "skype", "telegram"].includes(selectedPlatform2)
      ) {
        if (platformInputref.current.trim() === "") {
          setnewformmediumValueErr(true);
          error.newformmediumValue = "Must enter the field";
          setvalidationErr(error);
          return;
        } else if (
          selectedPlatform2 === "whatsApp" &&
          platformInputref.current.length < 7
        ) {
          setnewformmediumValueErr(true);
          error.newformmediumValue = `Whatsapp number must be at least 7 digits`;
          setvalidationErr(error);
          return;
        } else if (
          selectedPlatform2 === "skype" &&
          platformInputref.current.length < 5
        ) {
          setnewformmediumValueErr(true);
          error.newformmediumValue = ` Skype ID / Number must be at least 5 characters`;
          setvalidationErr(error);
          return;
        } else if (platformInputref.current.length < 5) {
          setnewformmediumValueErr(true);
          error.newformmediumValue = ` Telegram ID / Number must be at least 5 characters`;
          setvalidationErr(error);
          return;
        } else {
          setnewformuserNameErr(false);

          setnewformmediumValueErr(false);
        }
      }

      if (formData.newformmessage.trim() === "") {
        setnewformmediumValueErr(false);
        setnewformmessageErr(true);
        error.newformmessage = "Must enter the field";
        setvalidationErr(error);
      } else if (formData.newformmessage.length < 3) {
        setnewformmessageErr(true);
        error.newformmessage = "Message must contain at least 3 characters";
        setvalidationErr(error);
      } else if (formData.newformmessage.length > 250) {
        setnewformmessageErr(true);
        error.newformmessage = "Message can't exceed 250 characters";
        setvalidationErr(error);
      } else {
        setnewformmessageErr(false);
      }

      setvalidationErr(error);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePlatformInputChange = (e) => {
    const value = e.target.value;
    setPlatformInput(value);
    if (value && value.length > 0) {
      setnewformmediumErr(false);
      setvalidationErr((prev) => ({ ...prev, newformmedium: "" }));
    } else {
      setnewformmediumErr(true);
      setvalidationErr((prev) => ({
        ...prev,
        newformmedium: "Must enter the field",
      }));
    }
    // newValueref.current.newformmessage = "";
    validate(newValueref.current);
    if (
      selectedPlatform2 === "email" &&
      !/^[^\s@]+@[^\s@]+\.(com)$/.test(platformInputref.current)
    ) {
      validate(newValueref.current);
    }
  };

  const handleKeyPress = (e, maxLength, type) => {
    if (
      e.target.value.length >= maxLength &&
      ![
        "Backspace",
        "Delete",
        "Tab",
        "Escape",
        "Enter",
        "ArrowLeft",
        "ArrowRight",
      ].includes(e.key)
    ) {
      e.preventDefault();
      return;
    }

    if (type === "email") {
      const validEmailChars = /^[a-zA-Z0-9@._-]+$/;
      if (
        !validEmailChars.test(e.key) &&
        ![
          "Backspace",
          "Delete",
          "Tab",
          "Escape",
          "Enter",
          "ArrowLeft",
          "ArrowRight",
        ].includes(e.key)
      ) {
        e.preventDefault();
      }
      return;
    }

    if (type === "number") {
      const cursorPosition = e.target.selectionStart;
      const value = e.target.value;

      // Prevent space at the start
      if (e.key === " " && cursorPosition === 0) {
        e.preventDefault();
        return;
      }

      // Allow only digits, plus symbol at the start, spaces in the middle, and control keys
      if (
        !/[0-9 ]/.test(e.key) &&
        !(e.key === "+" && cursorPosition === 0) &&
        ![
          "Backspace",
          "Delete",
          "Tab",
          "Escape",
          "Enter",
          "ArrowLeft",
          "ArrowRight",
        ].includes(e.key)
      ) {
        e.preventDefault();
      }
      return;
    }

    if (type === "text") {
      const cursorPosition = e.target.selectionStart;
      const value = e.target.value;

      if (e.key === " " && cursorPosition === 0) {
        e.preventDefault();
        return;
      }
      if (
        value.length < maxLength ||
        [
          "Backspace",
          "Delete",
          "Tab",
          "Escape",
          "Enter",
          "ArrowLeft",
          "ArrowRight",
        ].includes(e.key)
      ) {
        return;
      }

      e.preventDefault();
    }
  };

  const handlePlatformSelect = (e, platform) => {
    e.preventDefault();
    setSelectedPlatform2(platform);
    setPlatformInput("");
  };

  const handleNewFormChange = async (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formData = { ...newValue, ...{ [name]: value } };
      setnewValue(formData);
      await validate(formData);

      if (selectedPlatform2 && !platformInputref.current) {
        setnewformmediumValueErr(true);
      } else {
        setnewformmediumValueErr(false);
      }

      // if (name === "newformmessage") {
      //   if (!value.trim()) {
      //     setnewformmessageErr(true);
      //   } else {
      //     setnewformmessageErr(false);
      //   }
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const [buttonLoader, setbuttonLoader] = useState(false);

  const sendServiceContact = async (e) => {
    try {
      e.preventDefault();
      validate(newValueref.current);
      console.log(newValueref.current);
      console.log(
        newValueref.current.newformuserName,
        newformuserNameErrref.current,
        newformmessageErrref.current,
        newformmobileErrref.current,
        newformmediumValueErrref.current,
        newformmobilecodeErr,
        newValueref.current.newformmessage
      );

      if (
        newValueref.current.newformuserName !== "" &&
        newformuserNameErrref.current === false &&
        newformmessageErrref.current === false &&
        newformmobileErrref.current === false &&
        newformmediumValueErrref.current == false &&
        newformmobilecodeErr === false &&
        newValueref.current.newformmessage != ""
      ) {
        // setbuttonLoader(true);

        newValueref.current.newformmedium = selectedPlatform2;
        newValueref.current.newformmediumValue = platformInputref.current;

        var obj = {
          apiUrl: httpService.sendServiceContactDetails,
          payload: newValueref.current,
        };
        var messageSent = await sendcontactpageContacts(obj);
        setbuttonLoader(false);

        if (messageSent.status == true) {
          toast.success(messageSent.Message);
        } else {
          toast.error("error", messageSent.Message);
        }
        newValueref.current.newformuserName = "";
        newValueref.current.newformmessage = "";
        newValueref.current.newformmobile = "";
        newValueref.current.newformmobilecode = "";
        setNewFormMobileValue("");
        setPlatformInput("");
        setNewFormNMobileCode("");
        setNewFormcountry();
        handleNewFormMobile("", "", "", "");
      } else {
        validate(newValueref.current);
      }
    } catch (errr) {
      console.log("errr", errr);
    }
  };

  const cancelpopup = () => {};

  return (
    <div>
      <header>
        <Header />
      </header>

      <main>
        {/* hero */}
        <section className="hero-margin">
          <div className="hero">
            <div className="landing-container">
              <div className="hero-contents">
                <h1 className="hero-title">
                  <span className="span-clr">Cryptocurrency </span>
                  <span>Exchange Development </span>
                  <span> Company</span>
                </h1>
                <div className="d-flex justify-content-center align-align-items-center gap-3 mt-4">
                  <a
                    href="#about"
                    // href="https://beleaftechnologies.com/contact-us"
                    className="secondary-btn"
                  >
                    Explore
                  </a>
                  <a
                    // href="https://beleaftechnologies.com/contact-us"
                    className="primary-btn"
                    // href="#contact"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    Get Demo
                  </a>
                </div>
              </div>
            </div>
            <img
              src={require("../assets/hero-bg-left.webp")}
              alt="cryptocurrency exchange development company"
              className="hero-bg-left"
            />
            <img
              src={require("../assets/hero-bg-right.webp")}
              alt="cryptocurrency exchange development company"
              className="hero-bg-right"
            />
          </div>
        </section>

        {/* about */}
        <section>
          <div className="landing-container">
            <div className="hero-grid-bg">
              <img
                src={require("../assets/hero-grid.webp")}
                alt="cryptocurrency exchange development company"
              />
            </div>
            <div className="about-card">
              <div className="about-card-inner" id="about">
                <div className="row about-row">
                  <div className="col-lg-7">
                    <div>
                      <h4 className="about-title">
                        Cryptocurrency
                        <span className="span-clr"> Exchange</span> Development
                        {/* <div className="content-md-inline"> </div> */}
                      </h4>
                      <p className="about-content">
                        As digital assets continue to gain popularity, so does
                        the need for secure, user-friendly platforms to buy,
                        sell, and trade these assets. A cryptocurrency exchange
                        is the foundation of the crypto trading ecosystem,
                        enabling users to convert traditional money into digital
                        currencies and trade between various crypto assets. For
                        businesses and entrepreneurs aiming to enter this space,
                        partnering with a cryptocurrency exchange development
                        company is key to building a reliable, secure, and
                        competitive platform.
                      </p>
                      <div className="mt-5">
                        <a
                          // href="https://beleaftechnologies.com/contact-us"
                          className="primary-btn"
                          // href="#contact"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          Get Demo
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="about-right">
                      <div className="about-gif">
                        <img
                          src={require("../assets/about-gif.gif")}
                          alt="cryptocurrency exchange development company"
                        />
                        {/* <img
                        src={ABOUTGRAD}
                        alt="cryptocurrency exchange development company"
                      /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* role */}
        <section>
          <div className="landing-container">
            <div className="role">
              <h4 className="role-title">
                Role of a <span class="span-clr">Cryptocurrency Exchange</span>{" "}
                Development Company
              </h4>

              <div className="row role-row">
                <div className="col-lg-6">
                  <div>
                    <div className="role-left">
                      <img
                        src={require("../assets/role-check.webp")}
                        alt="cryptocurrency exchange development company"
                        className="role-left-img"
                      />
                      <p className="role-left-content">
                        A cryptocurrency exchange development company
                        specializes in creating platforms where users can trade
                        digital assets with ease and security. These companies
                        employ experienced developers who understand the
                        technical and security requirements needed for a
                        seamless trading experience.{" "}
                      </p>
                    </div>
                    <div>
                      <div className="role-left">
                        <img
                          src={require("../assets/role-check.webp")}
                          alt="cryptocurrency exchange development company"
                          className="role-left-img"
                        />
                        <div>
                          <p className="role-left-content">
                            Businesses often seek out a crypto exchange
                            development company to leverage their expertise in
                            building scalable, high-performance exchanges
                            tailored to meet the unique needs of the market. By
                            collaborating with specialists in crypto exchange
                            development, companies can ensure their platform
                            adheres to industry standards while providing an
                            intuitive experience for users.
                          </p>
                          <div className="mt-4">
                            <a
                              // href="https://beleaftechnologies.com/contact-us"
                              className="primary-btn"
                              // href="#contact"
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              Get Consultation
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 text-center">
                  <img
                    src={require("../assets/role-right-img.webp")}
                    alt="cryptocurrency exchange development company"
                    className="role-right-img"
                  />
                </div>
              </div>
              <div className="hero-grid-bg">
                <img
                  src={require("../assets/hero-grid.webp")}
                  alt="cryptocurrency exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        {/* types */}
        <section className="types" id="types">
          <div className="landing-container">
            <h4 className="types-title">
              <span class="span-clr">Types of </span>Cryptocurrency Exchange
              Development
              {/* <div className="content-md-inline"></div> */}
            </h4>
            <p className="types-content">
              Cryptocurrency exchanges come in various types, each with distinct{" "}
              <div className="content-md-inline">
                benefits and technical requirements.
              </div>
            </p>

            <div className="row type-card-row">
              <div className="col-lg-3">
                <div className="type-cards">
                  <div className="type-num">01</div>
                  <h6 className="type-card-title">Centralized Exchanges</h6>
                  <p className="type-card-content">
                    CEX are the most common, where a central authority manages
                    all transactions and maintains liquidity.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                {" "}
                <div className="type-cards">
                  <div className="type-num">02</div>
                  <h6 className="type-card-title">Decentralized Exchanges</h6>
                  <p className="type-card-content">
                    DEX offer a peer-to-peer trading experience without
                    intermediaries, adding an extra layer of privacy and
                    security.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                {" "}
                <div className="type-cards">
                  <div className="type-num">03</div>
                  <h6 className="type-card-title">Hybrid Exchanges</h6>
                  <p className="type-card-content">
                    It combines the benefits of CEX and DEX, providing enhanced
                    security and faster transactions.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                {" "}
                <div className="type-cards">
                  <div className="type-num">04</div>
                  <h6 className="type-card-title">Peer-to-Peer Exchange</h6>
                  <p className="type-card-content">
                    (P2P) Exchanges allow users to trade directly with each
                    other, ensuring greater control over transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="type-btn-wrap text-center">
              <a
                // href="https://beleaftechnologies.com/contact-us"
                className="primary-btn"
                href="#contact"
              >
                Free Demo
              </a>
            </div>
          </div>
          <img
            src={require("../assets/type-bg.png")}
            alt="cryptocurrency exchange development company"
            className="types-bg"
          />
        </section>

        {/* key features */}
        <section id="features" className="features">
          <div className="landing-container">
            <h4 className="key-title text-sm-start">
              <span class="span-clr">Key Features </span>of a Advanced{" "}
              Cryptocurrency Exchange Development
              {/* <div className="content-md-inline"></div> */}
            </h4>
            <div className="mt-4">
              <a className="primary-btn" href="#contact">
                Expert Advice
              </a>
            </div>
          </div>
          <div className="features-row-outer">
            <div className="landing-container">
              <div className="row features-row">
                <div className="col-lg-3">
                  <div className="feature-cards feature-border-right">
                    <img
                      src={require("../assets/security.webp")}
                      alt="cryptocurrency exchange development company"
                      className="feature-card-img"
                    />
                    <h4 className="feature-card-title">Security features</h4>
                    <p className="feature-card-content">
                      {" "}
                      A successful cryptocurrency exchange development must
                      integrate advanced security measures, such as KYC/AML
                      compliance and two-factor authentication (2FA), to protect
                      users and their assets.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="feature-cards feature-border-right">
                    <img
                      src={require("../assets/multi.webp")}
                      alt="cryptocurrency exchange development company"
                      className="feature-card-img"
                    />
                    <h4 className="feature-card-title">
                      {" "}
                      Multi-currency wallet integration
                    </h4>
                    <p className="feature-card-content">
                      {" "}
                      multi-currency wallet integration enables users to store
                      various digital assets securely.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="feature-cards feature-border-right">
                    <img
                      src={require("../assets/liquidity.webp")}
                      alt="cryptocurrency exchange development company"
                      className="feature-card-img"
                    />
                    <h4 className="feature-card-title">Liquidity management</h4>
                    <p className="feature-card-content">
                      {" "}
                      Liquidity management and a reliable trading engine are
                      essential for smooth trading operations.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="feature-cards">
                    <img
                      src={require("../assets/ui.webp")}
                      alt="cryptocurrency exchange development company"
                      className="feature-card-img"
                    />
                    <h4 className="feature-card-title"> UI/UX design</h4>
                    <p className="feature-card-content">
                      {" "}
                      Intuitive UI/UX design ensures users can navigate the
                      platform easily. Scalability is another crucial factor, as
                      it enables the platform to handle an increasing number of
                      users and trades as the business grows.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* importance */}
        <section id="importance">
          <div className="landing-container">
            <div className="about-card">
              <div className="importance-card-inner">
                <h4 className="importance-title">
                  Importance of <span class="span-clr">Security in Crypto</span>{" "}
                  <span className="span-clr">Exchange </span>
                  Development
                  {/* <div className="content-md-inline"></div> */}
                </h4>
                <p className="importance-content">
                  Security remains a top priority in cryptocurrency exchange
                  development, as digital assets are highly valuable and
                  susceptible to cyber threats. A crypto exchange development
                  company will implement measures like cold storage for assets,
                  anti-phishing mechanisms, and DDoS protection to safeguard the
                  platform from external threats. By incorporating these
                  security features, businesses can build user trust and attract
                  a loyal user base who feel confident about the safety of their
                  funds and data.
                </p>
                <div className="text-center mt-4">
                  <a
                    // href="https://beleaftechnologies.com/contact-us"
                    className="primary-btn"
                    href="#contact"
                  >
                    {" "}
                    Build Your Exchange
                  </a>
                </div>
                <div className="text-center">
                  <img
                    src={require("../assets/hero-grid.webp")}
                    alt="cryptocurrency exchange development company"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* steps */}
        <section>
          <div className="landing-container">
            <h4 className="step-title">
              Steps in the <span className="span-clr">Development</span>{" "}
              <span className="span-clr">Process</span>
              {/* <div className="content-md-inline"></div> */}
            </h4>

            <div>
              <div className="row mt-5 justify-content-center align-items-center step-cards-row-one">
                <div className="col-lg-3 col-md-4">
                  <div className="steps-outer">
                    <div className="steps-inner inner-one">
                      <div>
                        <div className="text-center">
                          <img
                            src={require("../assets/plan.webp")}
                            alt="cryptocurrency exchange development company"
                            className="step-img"
                          />
                        </div>

                        <div className="step-card-contents-wrap">
                          <h6 className="step-card-title">
                            Planning and <div>consultation phase</div>
                          </h6>
                          <p className="step-card-content">
                            The development process begins with a consultation
                            phase where the cryptocurrency exchange development
                            company works with the client to understand their
                            vision and objectives.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1">
                  <div className="step-abs-img">
                    <img
                      src={require("../assets/step-one.webp")}
                      alt="cryptocurrency exchange development company"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="steps-outer">
                    <div className="steps-inner inner-two">
                      <div>
                        <div className="text-center">
                          <img
                            src={require("../assets/design.webp")}
                            alt="cryptocurrency exchange development company"
                            className="step-img"
                          />
                        </div>

                        <div className="step-card-contents-wrap">
                          <h6 className="step-card-title">
                            Design and <div>prototyping</div>
                          </h6>
                          <p className="step-card-content">
                            This phase is followed by design and prototyping,
                            where the user interface and user experience are
                            mapped out.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1">
                  <div className="step-abs-img">
                    <img
                      src={require("../assets/step-two.webp")}
                      alt="cryptocurrency exchange development company"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="steps-outer">
                    <div className="steps-inner inner-three">
                      <div>
                        <div className="text-center">
                          <img
                            src={require("../assets/dev.webp")}
                            alt="cryptocurrency exchange development company"
                            className="step-img"
                          />
                        </div>

                        <div className="step-card-contents-wrap">
                          <h6 className="step-card-title">
                            Development and <div>integration</div>
                          </h6>
                          <p className="step-card-content">
                            During the development and integration stage,
                            developers bring the exchange to life by coding the
                            core functionalities and integrating essential
                            features.
                          </p>
                        </div>
                      </div>
                    </div>
                    <img
                      src={require("../assets/step-three.webp")}
                      alt="cryptocurrency exchange development company"
                      className="step-three-img"
                    />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center step-cards-row-two">
                {/* <div className="col-lg-1"></div> */}
                <div className="col-lg-3 col-md-4 p-0">
                  <div className="steps-outer">
                    <div className="steps-inner inner-four">
                      <div>
                        <div className="text-center">
                          <img
                            src={require("../assets/test.webp")}
                            alt="cryptocurrency exchange development company"
                            className="step-img"
                          />
                        </div>

                        <div className="step-card-contents-wrap">
                          <h6 className="step-card-title">
                            Testing and quality <div>assurance</div>
                          </h6>
                          <p className="step-card-content">
                            After rigorous testing and quality assurance, the
                            platform is launched.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-1 col-md-1">
                  <div className="step-abs-img">
                    <img
                      src={require("../assets/step-four.webp")}
                      alt="cryptocurrency exchange development company"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 p-0">
                  <div className="steps-outer">
                    <div className="steps-inner inner-five">
                      <div>
                        <div className="text-center">
                          <img
                            src={require("../assets/launch.webp")}
                            alt="cryptocurrency exchange development company"
                            className="step-img"
                          />
                        </div>

                        <div className="step-card-contents-wrap">
                          <h6 className="step-card-title">
                            Launch and post - <div>launch support</div>
                          </h6>
                          <p className="step-card-content">
                            Post-launch support ensures smooth operations, with
                            ongoing maintenance and feature updates.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-5 text-center">
                <a
                  className="primary-btn"
                  href="#contact"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Develop Your Exchange
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* benefits */}
        <section id="benefits">
          <div className="landing-container">
            <div className="role">
              <h4 className="benefit-title">
                Benefits of <span class="span-clr">Hiring a Professional </span>{" "}
                Crypto{" "}
                <div className="content-md-inline">
                  Exchange Development Company
                </div>
              </h4>

              <div className="row role-row">
                <div className="col-lg-6">
                  <div>
                    <div className="role-left">
                      <img
                        src={require("../assets/role-check.webp")}
                        alt="cryptocurrency exchange development company"
                        className="role-left-img"
                      />
                      <p className="role-left-content">
                        Collaborating with an experienced crypto exchange
                        development company offers numerous benefits.
                        Professional companies have in-depth knowledge of
                        blockchain technology, ensuring that the exchange is
                        built with the latest tools and security protocols.{" "}
                      </p>
                    </div>
                    <div>
                      <div className="role-left">
                        <img
                          src={require("../assets/role-check.webp")}
                          alt="cryptocurrency exchange development company"
                          className="role-left-img"
                        />
                        <div>
                          <p className="role-left-content">
                            Customization is another advantage, allowing
                            companies to build a platform that aligns perfectly
                            with their brand and user requirements. Moreover,
                            reputable development firms provide regulatory
                            compliance support, helping clients navigate the
                            complex legal landscape surrounding cryptocurrency
                            exchanges.{" "}
                          </p>
                          <div className="mt-5">
                            <a
                              // href="https://beleaftechnologies.com/contact-us"
                              className="primary-btn"
                              // href="#contact"
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              Get Consultation
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 text-center">
                  <img
                    src={require("../assets/benefits-imG.png")}
                    alt="cryptocurrency exchange development company"
                    className="role-right-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* cost */}
        <section className="cost-mt">
          <div className="landing-container">
            <h4 className="cost-title">
              <span class="span-clr">Cost and Time</span> Considerations for{" "}
              Cryptocurrency Exchange Development
              {/* <div className="content-md-inline"></div> */}
            </h4>
            <div className="row cost-row">
              <div className="col-lg-6 text-center">
                <img
                  src={require("../assets/cost-img.png")}
                  alt="cryptocurrency exchange development company"
                  className="role-right-img"
                />
              </div>
              <div className="col-lg-6">
                <div className="role-left">
                  <img
                    src={require("../assets/role-check.webp")}
                    alt="cryptocurrency exchange development company"
                    className="role-left-img"
                  />
                  <p className="role-left-content">
                    The cost of developing a cryptocurrency exchange varies
                    based on the features, security level, and compliance
                    requirements. A basic exchange with standard features costs
                    less, while a high-security exchange with custom features
                    and regulatory guidance will be more expensive. Development
                    time can range from a few months to over a year, depending
                    on complexity. Partnering with an experienced crypto
                    exchange development company can help manage these aspects
                    effectively, optimizing both time and cost.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* how-to-choose */}
        <section>
          <div className="landing-container">
            <h4 className="choose-title">
              How to <span class="span-clr">Choose the Right</span>{" "}
              Cryptocurrency Exchange Development Company
              {/* <div className="content-md-inline"></div> */}
            </h4>
            <div className="row cost-row">
              <div className="col-lg-6">
                <div className="role-left">
                  <img
                    src={require("../assets/role-check.webp")}
                    alt="cryptocurrency exchange development company"
                    className="role-left-img"
                  />
                  <p className="role-left-content">
                    When selecting a cryptocurrency exchange development
                    company, it’s essential to assess their experience and
                    expertise in the field. Look for companies with a strong
                    portfolio and case studies that demonstrate successful
                    projects. A robust technology stack is also crucial for
                    building a modern, high-performance exchange. Checking
                    client reviews and seeking a provider offering comprehensive
                    post-launch support can further ensure that the development
                    process goes smoothly from start to finish.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 text-center">
                <img
                  src={require("../assets/choose-img.png")}
                  alt="cryptocurrency exchange development company"
                  className="role-right-img choose-img"
                />
              </div>
            </div>
          </div>
        </section>

        {/* future-trends */}
        <section>
          <div className="landing-container">
            <h4 className="role-title">
              <span class="span-clr">Future Trends</span> in Cryptocurrency{" "}
              Exchange Development
              {/* <div className="content-md-inline"></div> */}
            </h4>
            <div className="row cost-row">
              <div className="col-lg-6 text-center">
                <img
                  src={require("../assets/trends-img.png")}
                  alt="cryptocurrency exchange development company"
                  className="role-right-img"
                />
              </div>
              <div className="col-lg-6">
                <div className="role-left">
                  <img
                    src={require("../assets/role-check.webp")}
                    alt="cryptocurrency exchange development company"
                    className="role-left-img"
                  />
                  <p className="role-left-content">
                    As the industry evolves, several trends are shaping the
                    future of crypto exchanges. AI and machine learning are
                    being incorporated for enhanced security and to improve user
                    experiences through personalized recommendations.
                    Decentralized finance (DeFi) integration is another
                    significant trend, enabling users to access lending,
                    staking, and other financial services directly from the
                    exchange. Tokenization and NFTs are also gaining traction,
                    allowing exchanges to diversify their offerings and tap into
                    the growing digital asset economy.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* exclusive */}
        <section>
          <div className="landing-container">
            <div className="exclusive-outer">
              <div className="exclusive-inner">
                <h4 className="role-title exclusive-content">
                  Our Exclusive<span class="span-clr"> Marketing Support</span>{" "}
                </h4>
                <p className="role-left-content mt-4 exclusive-content">
                  We offer complete marketing support to help your crypto
                  exchange grow and succeed. Our team understands the unique
                  challenges of the cryptocurrency market and creates targeted
                  strategies to attract and engage your ideal users. From
                  building brand awareness to driving traffic and boosting user
                  retention, we handle every step of your marketing journey. Our
                  services include social media campaigns, content creation,
                  SEO, Branding and more to ensure your exchange gains
                  visibility and trust in the crypto space. Let us be your
                  partner in making your crypto exchange stand out and grow
                  rapidly in this competitive industry.
                </p>

                <img
                  src={require("../assets/exclusive-bg.png")}
                  alt="cryptocurrency exchange development company"
                  className="exclusive-left"
                />
                <img
                  src={require("../assets/exclusive-bg.png")}
                  alt="cryptocurrency exchange development company"
                  className="exclusive-right"
                />
              </div>
            </div>
          </div>
        </section>

        {/* navigate regulatory */}
        <section>
          <div className="landing-container">
            <div className="row navigate-row">
              <div className="col-lg-6">
                <h4 className="help-title text-sm-start">
                  We Help You Navigate
                  <div className="content-md-inline">Regulatory Compliance</div>
                </h4>
                <div className="navigate-btn-wrap">
                  <a
                    // href="https://beleaftechnologies.com/contact-us"
                    className="primary-btn mt-4"
                    href="#contact"
                  >
                    Get Consultation
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <p className="role-left-content">
                    Starting a crypto exchange? One of the most critical steps
                    is getting the right license to operate legally and build
                    trust with your users. We specialize in helping businesses
                    obtain the VARA license and FIU Registration they need for
                    crypto exchange development.
                  </p>
                  <p className="role-left-content">
                    Our team guides you through each step of the licensing
                    process, from understanding regulations to gathering the
                    required documents. With our support, you can confidently
                    meet legal requirements, ensuring that your crypto exchange
                    is compliant and ready for the market. Let us handle the
                    licensing complexities so you can focus on building a
                    successful crypto exchange.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* conclusion */}
        <section id="conclusion" className="conclusion">
          <div className="landing-container">
            <div className="about-card">
              <div className="importance-card-inner">
                <h4 className="role-title">Conclusion</h4>
                <p className="importance-content">
                  Partnering with a professional cryptocurrency exchange
                  development company provides businesses with the resources and
                  expertise to build a secure, user-friendly, and scalable
                  exchange platform. By leveraging the latest technology and
                  adhering to industry standards, companies can launch a
                  successful platform that attracts and retains a loyal user
                  base in the dynamic world of digital assets.
                </p>
                <div className="text-center mt-4">
                  <a
                    // href="https://beleaftechnologies.com/contact-us"
                    href="#contact"
                    className="primary-btn"
                  >
                    To Know More
                  </a>
                </div>
                <div className="text-center">
                  <img
                    src={require("../assets/hero-grid.webp")}
                    alt="cryptocurrency exchange development company"
                    className="w-100 exclusive-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* faq */}
        <article className="landing-container">
          <div className="landing-faq-wrapper">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="landing-faq-left-title">
                  Frequently Asked <br />{" "}
                  <span className="faq-span">Questions.</span> {""}
                </h6>
              </div>
              <div className="col-lg-6">
                <div>
                  <div class="accordion" id="accordionFlushExample">
                    <div className="landing-faq-card">
                      <div class="accordion-item faq-outer">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="landing-faq-title collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            How long does it take to develop a crypto exchange?
                          </button>
                        </h2>

                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="landing-faq-content">
                            The time to develop a crypto exchange varies based
                            on complexity, typically ranging from a few months
                            to over a year for fully customized platforms.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="landing-faq-card">
                      <div class="accordion-item faq-outer">
                        <h2 class="accordion-header" id="flush-headingTwo">
                          <button
                            class="landing-faq-title collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            What types of crypto exchanges can be developed?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="landing-faq-content">
                            A crypto exchange development company can build
                            centralized, decentralized, hybrid, and peer-to-peer
                            exchanges to suit different business models and user
                            needs.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="landing-faq-card">
                      <div class="accordion-item faq-outer">
                        <h2 class="accordion-header" id="flush-headingThree">
                          <button
                            class="landing-faq-title collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Why is security crucial in crypto exchange
                            development?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="landing-faq-content">
                            Security protects user assets and data from cyber
                            threats. Key measures include two-factor
                            authentication, encryption, and anti-DDoS protocols.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="landing-faq-card">
                      <div class="accordion-item faq-outer">
                        <h2 class="accordion-header" id="flush-headingFour">
                          <button
                            class="landing-faq-title collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                          >
                            How can I choose the right crypto exchange
                            development company?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingFour"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="landing-faq-content">
                            Choose a company with proven experience, a strong
                            tech stack, positive reviews, and post-launch
                            support to ensure a reliable and secure platform.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        {/* get in touch */}
        <section className="footer-form">
          <div className="landing-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="landing__contact">
                  <div>
                    <h5 className="landing__contact-title">Get In Touch</h5>
                    <p className="landing__contact-subContent">
                      We're here to assist you. Contact us for tailored
                      solutions to elevate your business.
                    </p>
                  </div>

                  <div className="landing__contact--address">
                    <div className="landing__contact--address-wrap contact-primary-opacity">
                      <img
                        src={TEMPLE}
                        alt="Exchange development"
                        loading="lazy"
                      />

                      <div className="landing__contact--address-info">
                        <h6>India - Madurai</h6>
                        <p>
                          5/24, Bharathiyar 7th street, S.S.Colony Madurai,
                          Tamil Nadu, <br /> Postal Code - 625 016, India.
                        </p>
                      </div>
                    </div>

                    <div className="landing__contact--address-wrap contact-opacity">
                      <img
                        src={GATE}
                        alt="Exchange development"
                        loading="lazy"
                      />

                      <div className="landing__contact--address-info">
                        <h6>India - Thane</h6>
                        <p>
                          12, 4th floor Krishnasthal CHS, Mira Gaon Mira Road
                          East, Thane, Maharastra, <br /> Postal Code - 401 107,
                          India.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" id="contact">
                <div className="landing__contact--form">
                  <form>
                    <h4 className="landing__contact--form-title">
                      Your Details
                    </h4>

                    <div className="mt-4">
                      <label htmlFor="form-name">Name</label> <br />
                      <input
                        type="text"
                        id="form-name"
                        placeholder="Enter your name"
                        className="landing__form-input margin-bottom-land"
                        name="newformuserName"
                        autoComplete="off"
                        value={newformuserName}
                        // name="newformuserName"
                        max="50"
                        maxLength="50"
                        onChange={handleNewFormChange}
                        onKeyPress={(e) => handleKeyPress(e, 50, "text")}
                      />
                      {newformuserNameErrref.current &&
                      newformuserNameErrref.current === true ? (
                        <div className="err-text mt-3">
                          {" "}
                          {validationErrref.current.newformuserName}{" "}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="mt-4">
                      <label htmlFor="mobile">Mobile Number</label> <br />
                      <PhoneInput
                        country={"us"}
                        value={NewFormMobileValue}
                        inputProps={{
                          name: "phonenumber",
                          required: true,
                        }}
                        autoComplete="off"
                        className="form-phone landing__form-input margin-bottom-land"
                        onChange={(value, countryObj, e, formattedValue) =>
                          handleNewFormMobile(
                            value,
                            countryObj,
                            e,
                            formattedValue
                          )
                        }
                      />
                      {newformmobileErrref.current &&
                      newformmobileErrref.current === true ? (
                        <div className="err-text mt-3">
                          {validationErrref.current.newformmobile}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="mt-4">
                      <label htmlFor="form-contact">Contact Medium</label>{" "}
                      <br />
                      <div className="form-contact-medium">
                        <button
                          type="button"
                          className="dropdown-toggle landing__form-dropdown"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {selectedPlatform2 == "whatsApp" ? (
                            <i className="fa-brands fa-whatsapp"></i>
                          ) : selectedPlatform2 == "telegram" ? (
                            <i className="fa-brands fa-telegram"></i>
                          ) : selectedPlatform2 == "skype" ? (
                            <i className="fa-brands fa-skype"></i>
                          ) : selectedPlatform2 == "email" ? (
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            ""
                          )}
                        </button>
                        <div className="dropdown-menu landing__form-drop-menu">
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => handlePlatformSelect(e, "whatsApp")}
                          >
                            <i className="fa-brands fa-whatsapp"></i>
                          </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => handlePlatformSelect(e, "telegram")}
                          >
                            <i className="fa-brands fa-telegram"></i>
                          </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => handlePlatformSelect(e, "skype")}
                          >
                            <i className="fa-brands fa-skype"></i>
                          </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => handlePlatformSelect(e, "email")}
                          >
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>

                        {selectedPlatform2 && (
                          <input
                            type="text"
                            className="landing__contact-medium-input"
                            placeholder={`Enter your ${selectedPlatform2} ${
                              selectedPlatform2 == "email"
                                ? "Address"
                                : selectedPlatform2 === "whatsApp"
                                ? "number"
                                : "number / ID"
                            }`}
                            maxLength={
                              selectedPlatform2 === "email"
                                ? 250
                                : selectedPlatform2 === "whatsApp"
                                ? 15
                                : 32
                            }
                            value={platformInputref.current}
                            autoComplete="off"
                            onChange={handlePlatformInputChange}
                            onKeyPress={(e) =>
                              handleKeyPress(
                                e,
                                selectedPlatform2 === "email"
                                  ? 250
                                  : selectedPlatform2 === "whatsApp"
                                  ? 15
                                  : 32,
                                selectedPlatform2 === "email"
                                  ? "email"
                                  : selectedPlatform2 === "whatsApp"
                                  ? "number"
                                  : "text"
                              )
                            }
                          />
                        )}
                      </div>
                      {newformmediumValueErrref.current &&
                      newformmediumValueErrref.current === true ? (
                        <span className="err-text mt-3">
                          {validationErrref.current.newformmediumValue}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="mt-4">
                      <label htmlFor="message">
                        Tell Us About Your Project
                      </label>
                      <br />
                      <textarea
                        id="message"
                        placeholder="Message"
                        className="landing__form-input landing-textarea landing__form-input margin-bottom-land"
                        rows="5"
                        name="newformmessage"
                        value={newformmessage}
                        max="250"
                        maxLength="250"
                        autoComplete="off"
                        onChange={handleNewFormChange}
                        onKeyPress={(e) => handleKeyPress(e, 250, "text")}
                      ></textarea>

                      {newformmessageErrref.current == true ? (
                        <div className="err-text mt-3">
                          {validationErrref.current.newformmessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="text-center mt-4">
                      {buttonLoader == true ? (
                        <button className="primary-btn" disabled>
                          loading....
                          <span className="mx-2 right-arr">
                            <i className="fa-solid fa-arrow-right"></i>
                          </span>
                        </button>
                      ) : (
                        <button
                          className="primary-btn"
                          onClick={sendServiceContact}
                        >
                          Submit
                          <span className="mx-2 right-arr">
                            <i className="fa-solid fa-arrow-right"></i>
                          </span>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="copyrights">
          <div className="landing-container">
            <div className="copyright-outer">
              <p className="copyright-title">
                &copy; 2024 Beleaf Technologies All Rights Reserved
              </p>

              <div className="social-icons-wrap">
                <span className="social-icon">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/beleaf-technologies-blf/"
                  >
                    <i class="fa-brands fa-linkedin-in"></i>
                  </a>
                </span>
                <span className="social-icon">
                  <a href="https://telegram.me/BeleafSoftTech" target="_blank">
                    <i class="fa-brands fa-telegram"></i>
                  </a>
                </span>
                <span className="social-icon">
                  <a
                    href="mailto:business@beleaftechnologies.com"
                    target="_blank"
                  >
                    <i class="fa-regular fa-envelope"></i>
                  </a>
                </span>
                <span className="social-icon">
                  <a href="https://wa.me/7904323274" target="_blank">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* New Pop Up */}
        {/* <div id="myModal" class="modal fade contact_model" role="dialog">
          <div class="modal-dialog modal-md ">
            <div class="modal-content ">
              <div className=" contact-popup-form">
                <form>
                  <h4 className="landing__contact--form-title">Your Details</h4>

                  <div className="mt-4">
                    <label htmlFor="form-name">Name</label> <br />
                    <input
                      type="text"
                      id="form-name"
                      placeholder="Enter your name"
                      className="landing__form-input margin-bottom-land"
                      name="newformuserName"
                      autoComplete="off"
                      value={newformuserName}
                      // name="newformuserName"
                      max="50"
                      maxLength="50"
                      onChange={handleNewFormChange}
                      onKeyPress={(e) => handleKeyPress(e, 50, "text")}
                    />
                    {newformuserNameErrref.current &&
                    newformuserNameErrref.current === true ? (
                      <div className="err-text mt-3">
                        {" "}
                        {validationErrref.current.newformuserName}{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mt-4">
                    <label htmlFor="mobile">Mobile Number</label> <br />
                    <PhoneInput
                      country={"us"}
                      value={NewFormMobileValue}
                      inputProps={{
                        name: "phonenumber",
                        required: true,
                      }}
                      autoComplete="off"
                      className="form-phone landing__form-input margin-bottom-land"
                      onChange={(value, countryObj, e, formattedValue) =>
                        handleNewFormMobile(
                          value,
                          countryObj,
                          e,
                          formattedValue
                        )
                      }
                    />
                    {newformmobileErrref.current &&
                    newformmobileErrref.current === true ? (
                      <div className="err-text mt-3">
                        {validationErrref.current.newformmobile}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mt-4">
                    <label htmlFor="form-contact">Contact Medium</label> <br />
                    <div className="form-contact-medium">
                      <button
                        type="button"
                        className="dropdown-toggle landing__form-dropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {selectedPlatform2 == "whatsApp" ? (
                          <i className="fa-brands fa-whatsapp"></i>
                        ) : selectedPlatform2 == "telegram" ? (
                          <i className="fa-brands fa-telegram"></i>
                        ) : selectedPlatform2 == "skype" ? (
                          <i className="fa-brands fa-skype"></i>
                        ) : selectedPlatform2 == "email" ? (
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        ) : (
                          ""
                        )}
                      </button>
                      <div className="dropdown-menu landing__form-drop-menu">
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => handlePlatformSelect(e, "whatsApp")}
                        >
                          <i className="fa-brands fa-whatsapp"></i>
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => handlePlatformSelect(e, "telegram")}
                        >
                          <i className="fa-brands fa-telegram"></i>
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => handlePlatformSelect(e, "skype")}
                        >
                          <i className="fa-brands fa-skype"></i>
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => handlePlatformSelect(e, "email")}
                        >
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </a>
                      </div>

                      {selectedPlatform2 && (
                        <input
                          type="text"
                          className="landing__contact-medium-input"
                          placeholder={`Enter your ${selectedPlatform2} ${
                            selectedPlatform2 == "email"
                              ? "Address"
                              : selectedPlatform2 === "whatsApp"
                              ? "number"
                              : "number / ID"
                          }`}
                          maxLength={
                            selectedPlatform2 === "email"
                              ? 250
                              : selectedPlatform2 === "whatsApp"
                              ? 15
                              : 32
                          }
                          value={platformInputref.current}
                          autoComplete="off"
                          onChange={handlePlatformInputChange}
                          onKeyPress={(e) =>
                            handleKeyPress(
                              e,
                              selectedPlatform2 === "email"
                                ? 250
                                : selectedPlatform2 === "whatsApp"
                                ? 15
                                : 32,
                              selectedPlatform2 === "email"
                                ? "email"
                                : selectedPlatform2 === "whatsApp"
                                ? "number"
                                : "text"
                            )
                          }
                        />
                      )}
                    </div>
                    {newformmediumValueErrref.current &&
                    newformmediumValueErrref.current === true ? (
                      <span className="err-text mt-3">
                        {validationErrref.current.newformmediumValue}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mt-4">
                    <label htmlFor="message">Tell Us About Your Project</label>
                    <br />
                    <textarea
                      id="message"
                      placeholder="Message"
                      className="landing__form-input landing-textarea landing__form-input margin-bottom-land"
                      rows="5"
                      name="newformmessage"
                      value={newformmessage}
                      max="250"
                      maxLength="250"
                      autoComplete="off"
                      onChange={handleNewFormChange}
                      onKeyPress={(e) => handleKeyPress(e, 250, "text")}
                    ></textarea>

                    {newformmessageErrref.current == true ? (
                      <div className="err-text mt-3">
                        {validationErrref.current.newformmessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="text-center mt-4">
                    {buttonLoader == true ? (
                      <button className="primary-btn" disabled>
                        loading....
                        <span className="mx-2 right-arr">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </button>
                    ) : (
                      <button
                        className="primary-btn"
                        onClick={sendServiceContact}
                      >
                        Submit
                        <span className="mx-2 right-arr">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
        <PopupContact />
      </main>
    </div>
  );
};

export default Landing;
